import check from "./checked.png";

const Checked = () => {
  return (
    <div style={{ marginTop: "20px" }}>
      <img src={check} alt="" />
    </div>
  );
};

export default Checked;
