const unsubBody = {
  email: "",
  subscriptionPermissions: [
    {
      id: 2,
      name: "Building Wealth Newsletter",
      permission: "Unsubscribed",
      permissionCaptureDateTime: "2019-01-01T00:00:00Z",
    },
  ],
};

export default unsubBody;
