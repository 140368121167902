import { useState } from "react";
import axios from "axios";
import { useHistory } from "react-router-dom";
import LoadingScreen from "./LoadingScreen";
import { IoShieldCheckmarkOutline } from "react-icons/io5";
import triggerBody from "./TriggerRequest";
//REACT_APP_API_BASE_URL=http://connect-preference-api-dev.ds03.internal.tekside.io
//REACT_APP_API_BASE_URL=http://localhost:3001

const SubscribeForm = () => {
  const history = useHistory();
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errorText, setErrorText] = useState("errorText");
  var errorOccurred = 0;
  const [message, setMessage] = useState("");
  const [userSubmit, setUserSubmit] = useState(false);

  /**
   * Generates an id based off the current time.
   * @returns {String} Returns the generated string.
   */
  const generateId = () => {
    var text = Date.now() + Math.floor(Math.random() * 1000).toString();
    return text;
  };

  /**
   * Sends a GET req to trigger/send endpoint using the users email,
   * and the name for the trigger in the sendBody.
   * @param {event} event Generated on form submit
   */
  const handleTriggerRequest = async (event) => {
    event.preventDefault();
    setLoading(true);
    const axiosConfig = {
      headers: {
        appID: "082d4@&D9D2c$4C34F8D!34vD7",
      },
    };
    if (validateForm()) {
      const sendBody = triggerBody;
      sendBody.send.email = email;
      sendBody.send.triggerName = "Verify User";
      axios
        .get(
          `${process.env.REACT_APP_API_BASE_URL}/api/subscribe?email=` + email,
          axiosConfig
        )
        .then((response) => {
          try {
            sendBody.send.party_id = response.data.content[0].fields.party_id;
          } catch {
            sendBody.send.party_id = generateId();
          }
          axios
            .post(
              `${process.env.REACT_APP_API_BASE_URL}/api/trigger/send`,
              sendBody.send,
              axiosConfig
            )
            .then((res) => {
              setLoading(false);
              setUserSubmit(true);
              setMessage("Check your email for a verification link.");
            });
        })
        .catch((error) => {
          setLoading(false);
          setUserSubmit(true);
          setMessage("You're already subscribed to this service!");
        });
    }
  };

  function validateForm() {
    resetErrors();
    var success = true;
    //No Email
    if (email === "") {
      document.getElementById("emailError").innerHTML +=
        "Please enter your email";
      if (errorOccurred !== 0) {
        setTimeout(() => {
          setErrorText("errorText-blink");
        }, 100);
      }
      success = false;
      errorOccurred = 1;
      //Email Not Valid
    } else if (validateEmail(email)) {
      document.getElementById("emailError").innerHTML += "Enter a valid email";
      if (errorOccurred !== 0) {
        setTimeout(() => {
          setErrorText("errorText-blink");
        }, 100);
      }
      success = false;
      errorOccurred = 1;
    }
    //checkbox not selected
    if (document.getElementById("cbx").checked !== true) {
      document.getElementById("emailError3").innerHTML += "Please check above";
      if (errorOccurred !== 0) {
        setTimeout(() => {
          setErrorText("errorText-blink");
        }, 100);
      }
      success = false;
      errorOccurred = 1;
    }
    return success;
  }

  function resetErrors() {
    document.getElementById("emailError").innerHTML = "";
    document.getElementById("emailError3").innerHTML = "";
    document.getElementById("reqError").innerHTML = "";
    setErrorText("errorText");
  }

  function validateEmail(email) {
    var re =
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //return the opposite
    return !re.test(String(email).toLowerCase());
  }

  return (
    <div>
      {userSubmit ? (
        <div>
          <p className="preFormText">{message}</p>
          <IoShieldCheckmarkOutline size={200} color="#7DC243" />
        </div>
      ) : (
        <form name="myForm" id="myForm">
          <div className="formElement">
            <div className="labelContainer">
              <label className="label" htmlFor="email">
                Email
              </label>
            </div>
            <div className="inputContainer">
              <input
                type="text"
                name="email"
                id="email"
                placeholder="JohnSmith@servus.ca"
                className="inputText"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
              <div className={errorText} id="emailError" />
            </div>
          </div>
          <div className="formElement" style={{ fontSize: "14px" }}>
            <div className="cntr">
              <label htmlFor="cbx" className="label-cbx">
                <input
                  id="cbx"
                  type="checkbox"
                  className="invisible"
                  name="confirmationCheckbox"
                  value="confirm"
                />
                <div className="checkbox">
                  <svg width="20px" height="20px" viewBox="0 0 20 20">
                    <path
                      d="M3,1 L17,1 L17,1 C18.1045695,1 19,1.8954305 19,3 L19,
                      17 L19,17 C19,18.1045695 18.1045695,19 17,19 L3,19 L3,19 C1.8954305,
                      19 1,18.1045695 1,17 L1,3 L1,3 C1,1.8954305 1.8954305,1 3,1 Z"
                    />
                    <polyline points="4 11 8 15 16 6" />
                  </svg>
                </div>
                <span className="label" style={{ fontSize: "14px" }}>
                  Yes, I want to receive the
                  <em>Building Wealth eNewsletter.</em>
                </span>
              </label>
            </div>
          </div>
          <div className={errorText} id="emailError3" />
          <div className="formElement">
            <div
              className="g-recaptcha"
              data-sitekey="6LdBU6gUAAAAAIeTjgWoNHN71Ohq0wyztWti8RoW"
            />
            <div className={errorText} id="emailError3" />
          </div>
          <div className="formElement">
            <div className="subscribe">
              <button
                name="Subscribe"
                id="Subscribe"
                type="submit"
                className="submitButton"
                onClick={handleTriggerRequest}
              >
                Subscribe
              </button>
              {loading && <LoadingScreen />}
            </div>
          </div>
          <div className="formElement">
            <div className={errorText} id="reqError" />
          </div>
        </form>
      )}
    </div>
  );
};

export default SubscribeForm;
