import Logo from "./Logo";
import Header from "./Header";
import SubscribeForm from "./SubscribeForm";
import Legal from "./Legal";

const Subscribe = () => {
  return (
    <div>
      <Logo />
      <Header />
      <div className="container">
        <div>
          <p className="title">Subscribe to Building Wealth eNewsletter</p>
          <p className="preFormText">
            Building wealth is important for everyone. With this quarterly
            eNewsletter, learn more about investment strategies, wealth
            protection and estate planning, and how to strengthen your overall
            financial plan.
          </p>
          <SubscribeForm />
          <Legal />
        </div>
      </div>
    </div>
  );
};

export default Subscribe;
