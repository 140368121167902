import Subscribe from "./Subscribe";
import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import "./App.css";
import SubscribeConfirmation from "./SubscribeConfirmation";
import Unsubscribe from "./Unsubscribe";
import { QueryParamProvider } from "use-query-params";
import UnsubscribeConfirmation from "./UnsubscribeConfirmation";
import SubscribeConfirmationSubscription from "./SubscribeConfirmSubscription";

function App() {
  return (
    <Router>
      <QueryParamProvider ReactRouterRoute={Route}>
        <div className="App">
          <Route exact path="/">
            <Redirect to="/buildingwealth/subscribe.php" />
          </Route>
          <Route
            path="/buildingwealth/subscribe.php"
            exact
            component={() => <Subscribe />}
          />
          <Route
            path="/buildingwealth/subscribe-confirm.php"
            exact
            component={() => <SubscribeConfirmation />}
          />
          <Route
            path="/buildingwealth/unsubscribe.php"
            exact
            component={() => <Unsubscribe />}
          />
          <Route
            path="/buildingwealth/unsubscribe-confirm.php"
            exact
            component={() => <UnsubscribeConfirmation />}
          />
          <Route
            path="/buildingwealth/subscribe-confirm-subscription.php"
            exact
            component={() => <SubscribeConfirmationSubscription />}
          />
        </div>
      </QueryParamProvider>
    </Router>
  );
}

export default App;
