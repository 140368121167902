const Legal = () => {
  return (
    <div className="legal">
      <p>
        This subscription is powered by Connect on behalf of Servus Credit
        Union. For other Servus email communications, proceed to the servus.ca
        subscription form at{" "}
        <a
          href="https://www.servus.ca/about/contact-us/email-subscription-form"
          style={{ color: "inherit", textDecoration: "underline" }}
        >
          https://www.servus.ca/about/contact-us/email-subscription-form
        </a>
        or through your online banking.
      </p>

      <p>
        Servus values your privacy. At no time will the information provided in
        this form be made available or sold to any company not related to, or
        providing support services to, Servus Credit Union.
      </p>

      <p>
        To learn more about Canada&rsquo;s anti-spam legislation{" "}
        <a
          href="https://www.fightspam.gc.ca/eic/site/030.nsf/eng/home"
          style={{ color: "inherit", textDecoration: "underline" }}
        >
          click here
        </a>
      </p>

      <p>
        Should you have any questions related to how Servus communicates with
        you please{" "}
        <a
          href="https://www.servus.ca/about/contact-us"
          style={{ color: "inherit", textDecoration: "underline" }}
        >
          Contact
        </a>
        .
      </p>
    </div>
  );
};

export default Legal;
