import Header from "./Header";
import Legal from "./Legal";
import Logo from "./Logo";

const UnsubscribeConfirmation = () => {
  return (
    <div>
      <Logo />

      <Header />
      <div className="container">
        <p className="title">We're sorry to see you go</p>
        <p className="preFormText">
          You have unsubscribed from Building Wealth.
        </p>
        <p className="preFormText">
          If you'd like to unsubscribe from other Servus communications, you can
          do so on servus.ca.
        </p>
        <div>
          <div className="formElement">
            <a
              href="https://www.servus.ca/about/contact-us/email-subscription-form"
              name="UnsubscribeMore"
              id="UnsubscribeMore"
              className="submitButton"
              style={{
                paddingLeft: "20px",
                paddingRight: "20px",
                textDecoration: "none",
              }}
            >
              Update other Servus subscriptions
            </a>
          </div>
        </div>
        <p className="preFormText">
          Note: If you're a Servus member choosing to unsubscribe, you'll
          continue to receive operational emails about your account and
          transactions with Servus Credit Union.
        </p>
        <Legal />
      </div>
    </div>
  );
};

export default UnsubscribeConfirmation;
