import logo from "./servus_logo.png";

const Logo = () => {
  return (
    <div className="container">
      <div style={{ paddingBottom: "10px" }}>
        <img
          src={logo}
          alt=""
          className="logo"
          style={{ width: "100%", maxWidth: "282px" }}
        />
      </div>
    </div>
  );
};

export default Logo;
