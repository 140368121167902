const triggerBody = {
  //testing
  send: {
    email: "",
    triggerName: "Verify User",
    company: "Servus Credit Union",
    party_id: "",
  },
  verify: {
    id: "",
  },
};

export default triggerBody;
