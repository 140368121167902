import Header from "./Header";
import Legal from "./Legal";
import Logo from "./Logo";
import UnsubscribeForm from "./UnsubscribeForm";

const Unsubscribe = () => {
  return (
    <div>
      <Logo />
      <Header />
      <div className="container">
        <p className="title">We're sorry to see you go</p>
        <p className="preFormText">
          Please confirm your email below and click unsubscribe to be removed
          from all future Building Wealth eNewsletter communications.
        </p>
        <UnsubscribeForm />
        <p className="preFormText">
          Note: If you're a Servus member choosing to unsubscribe, you'll
          continue to receive operational emails about your account and
          transactions with Servus Credit Union.
        </p>
        <Legal />
      </div>
    </div>
  );
};

export default Unsubscribe;
