import Checked from "./Checked";
import Header from "./Header";
import Logo from "./Logo";

const SubscribeConfirmation = () => {
  (function (a, b, c, d) {
    a = "https://tags.tiqcdn.com/utag/servus/main/prod/utag.js";
    b = document;
    c = "script";
    d = b.createElement(c);
    d.src = a;
    d.type = "text/java" + c;
    d.async = true;
    a = b.getElementsByTagName(c)[0];
    a.parentNode.insertBefore(d, a);
  })();

  return (
    <div>
      <Logo />
      <Header />

      <div className="container">
        <Checked />

        <div>
          <p className="preFormText">
            You've successfully subscribed to the quarterly Building Wealth
            eNewsletter. Look for the current issue arriving in your inbox
            tomorrow. If you change your mind, you can unsubscribe anytime.
          </p>

          <p className="preFormText" style={{ marginTop: "30px" }}>
            Thank you!
          </p>
        </div>
      </div>
    </div>
  );
};

export default SubscribeConfirmation;
