import React from "react";
import { AiOutlineLoading as LoadingIcon } from "react-icons/ai";

export default function LoadingScreen() {
  return (
    <div className="loadingContainer">
      <LoadingIcon className="loadingIcon" />
    </div>
  );
}
