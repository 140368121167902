import { useEffect, useState } from "react";
import Header from "./Header";
import Logo from "./Logo";
import axios from "axios";
import LoadingScreen from "./LoadingScreen";
import triggerBody from "./TriggerRequest";
import subBody from "./subReq";

const SubscribeConfirmationSubscription = () => {
  const [validId, setValidId] = useState(false);
  const [message, setMessage] = useState("");
  const [subStatus, setSubStatus] = useState(false);
  const [loading, setLoading] = useState(false);
  const lock = 0;

  useEffect(() => {
    setLoading(true);
    const axiosConfig = {
      headers: {
        appID: "082d4@&D9D2c$4C34F8D!34vD7",
      },
    };
    // eslint-disable-next-line no-restricted-globals
    let params = new URLSearchParams(location.search);
    const tokenId = params.get("id");
    if (tokenId !== "") {
      let sendBody = triggerBody;
      sendBody.verify.id = tokenId;
      axios
        .post(
          `${process.env.REACT_APP_API_BASE_URL}/api/trigger/verify`,
          sendBody.verify,
          axiosConfig
        )
        .then((response) => {
          if (response.data.success) {
            setValidId(true);
            setLoading(false);
            const sendSubBody = subBody;
            sendSubBody.email = response.data.email;
            try {
              axios
                .post(
                  `${process.env.REACT_APP_API_BASE_URL}/api/subscribe`,
                  sendSubBody,
                  axiosConfig
                )
                .then((response) => {
                  setSubStatus(true);
                  setMessage(
                    // eslint-disable-next-line max-len
                    "You've successfully subscribed to the quarterly Building Wealth eNewsletter. Look for the current issue arriving in your inbox tomorrow. If you change your mind, you can unsubscribe anytime."
                  );
                })
                .catch((error) => {
                  setSubStatus(true);
                  //User is already subscribed to the service
                  setMessage("Something went wrong. Please try again later.");
                  console.log(error);
                  console.log(response.data);
                });
            } catch (error) {
              console.error(error);
            }
          } else {
            console.log("Something went wrong");
            console.log(response);
          }
        });
    }
  }, [lock]);

  return (
    <div>
      <Logo />
      <Header />

      <div className="container">
        <div>
          {validId ? (
            <>
              {subStatus ? (
                <div className="">
                  <p className="preFormText">{message}</p>
                </div>
              ) : (
                <LoadingScreen />
              )}
            </>
          ) : (
            <>
              {loading ? (
                <LoadingScreen />
              ) : (
                <div className="rounded-section-centered">
                  <p className="preFormText">
                    Your secure link seems to have a bad ID. This can occur if
                    ID is expired or if it was edited. To resolve this issue
                    click here to send another secure link to the email address
                    you'd like to subscribe with.
                  </p>
                  <button>
                    <a href="/">Re-Send</a>
                  </button>
                </div>
              )}
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default SubscribeConfirmationSubscription;
