import axios from "axios";
import { useHistory } from "react-router-dom";
import { useQueryParam, StringParam } from "use-query-params";
import unsubBody from "./unsubReq";

const UnsubscribeForm = () => {
  const history = useHistory();
  const [email, setEmail] = useQueryParam("email", StringParam);

  const handleUnsubscribeRequest = async (event) => {
    event.preventDefault();
    if (validateForm()) {
      let date = new Date();
      let d = date.toISOString();
      const sendBody = unsubBody;
      sendBody.subscriptionPermissions[0].permissionCaptureDateTime = d;
      sendBody.email = email;

      const axiosConfig = {
        headers: {
          appid: "082d4@&D9D2c$4C34F8D!34vD7",
        },
      };

      try {
        await axios.post(
          `${process.env.REACT_APP_API_BASE_URL}/api/unsubscribe`,
          sendBody,
          axiosConfig
        );
        history.push("/buildingwealth/unsubscribe-confirm.php");
      } catch (error) {
        document.getElementById("reqError").innerHTML =
          error.response.data.message;
        console.error(error);
      }
    }
  };

  function validateForm() {
    resetErrors();
    var success = true;
    //No Email
    if (document.getElementById("email").value === "") {
      document.getElementById("emailError").innerHTML +=
        "Please enter your email";
      success = false;
      //Email Not Valid
    } else if (validateEmail(document.getElementById("email").value)) {
      document.getElementById("emailError").innerHTML += "Enter a valid email";
      success = false;
    }
    return success;
  }

  function resetErrors() {
    document.getElementById("emailError").innerHTML = "";
    document.getElementById("reqError").innerHTML = "";
  }

  function validateEmail(email) {
    var re =
      // eslint-disable-next-line max-len
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    //return the opposite
    return !re.test(String(email).toLowerCase());
  }

  return (
    <div>
      <form name="myForm">
        <div className="formElement">
          <div className="labelContainer">
            <label className="label" htmlFor="email">
              Email
            </label>
          </div>
          <div className="inputContainer">
            <input
              type="text"
              name="email"
              id="email"
              placeholder="JohnSmith@servus.ca"
              className="inputText"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="errorText" id="emailError" />
          </div>
        </div>
        <div className="formElement">
          <button
            name="Unsubscribe"
            id="Unsubscribe"
            type="submit"
            className="submitButton"
            onClick={handleUnsubscribeRequest}
          >
            Unsubscribe
          </button>
        </div>
        <div className="formElement">
          <div className="errorText" id="reqError" />
        </div>
      </form>
    </div>
  );
};

export default UnsubscribeForm;
