const subBody = {
  email: "",
  id: "",
  fields: [],
  subscriptionPermissions: [
    {
      name: "Building Wealth Newsletter",
      permission: "Express",
    },
  ],
};

export default subBody;
